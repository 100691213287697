import { useCallback } from "react";
import { map } from "lodash";

import { Color } from "@api";
import { useActiveWorkspaceId, useSession } from "@state/workspace";
import { useThemeColorState } from "@state/settings";
import { SystemPackages, useHasPackages } from "@state/packages";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";

import { Centered } from "@ui/container";
import { Heading, Text, TextLarge } from "@ui/text";
import { Divider } from "@ui/divider";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Check, Icon, LineAlt, Swatch } from "@ui/icon";
import { Label } from "@ui/label";
import { ColorSelect } from "@ui/select/color";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { showError } from "@ui/notifications";

import { SlackAuthorize } from "./slack-authorize";
import { NotionAuthorize } from "./notion-authorize";
import { GoogleAuthorize } from "./google-authorize";

import styles from "./settings-personal.module.css";

const SUGGESTED: Maybe<Color>[] = [
  undefined,
  "gray_2",
  "pink_4",
  "green_2",
  "blue_5",
  "yellow_4",
  "orange_4",
  "purple_5",
  "turquoise_5",
];

export function SettingsPersonalConnections() {
  const workspace = useActiveWorkspaceId();
  const session = useSession();
  const installed = useHasPackages(workspace, [
    SystemPackages.Notion,
    SystemPackages.Slack,
    SystemPackages.Google,
  ]);

  return (
    <Centered gap={20} stack="vertical" className={styles.page}>
      <VStack gap={0}>
        <Heading bold>Connections</Heading>
        <Text subtle>Connect your slack and notion accounts.</Text>
      </VStack>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        {installed[SystemPackages.Notion] !== false && (
          <>
            <SpaceBetween fit="container">
              <FillSpace>
                <VStack gap={0}>
                  <TextLarge bold>Notion Account</TextLarge>
                  <Text subtle>
                    Link your notion account to quickly create and search pages.
                  </Text>
                </VStack>
              </FillSpace>

              <HStack>
                {!!session?.auths?.notion?.user && (
                  <Label fit="content" icon={Check}>
                    Linked
                  </Label>
                )}
                <NotionAuthorize />
              </HStack>
            </SpaceBetween>
            <Divider direction="horizontal" />
          </>
        )}

        {installed[SystemPackages.Slack] !== false && (
          <>
            <SpaceBetween fit="container">
              <FillSpace>
                <VStack gap={0}>
                  <TextLarge bold>Slack Account</TextLarge>
                  <Text subtle>
                    Link your slack account to link and reply to messages.
                  </Text>
                </VStack>
              </FillSpace>

              <HStack>
                {!!session?.auths?.slack?.user && (
                  <Label fit="content" icon={Check}>
                    Linked
                  </Label>
                )}
                <SlackAuthorize />
              </HStack>
            </SpaceBetween>
            <Divider direction="horizontal" />
          </>
        )}

        {installed[SystemPackages.Google] !== false && (
          <>
            <SpaceBetween fit="container">
              <FillSpace>
                <VStack gap={0}>
                  <TextLarge bold>Google Account</TextLarge>
                  <Text subtle>
                    Link your Google account to access Google Drive and
                    Calendar.
                  </Text>
                </VStack>
              </FillSpace>

              <HStack>
                {!!session?.auths?.google?.user?.token && (
                  <Label fit="content" icon={Check}>
                    Linked
                  </Label>
                )}
                <GoogleAuthorize />
              </HStack>
            </SpaceBetween>
          </>
        )}
      </VStack>
    </Centered>
  );
}

export function SettingsPersonalThemes() {
  const [themeColor, setThemeColor] = useThemeColorState();
  const notSupported = useCallback(() => {
    showError("Not supported yet...");
  }, []);

  return (
    <Centered gap={20} stack="vertical" className={styles.page}>
      <VStack gap={0}>
        <Heading bold>Themes</Heading>
        <Text subtle>Make traction your own.</Text>
      </VStack>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Dark/Light Mode</TextLarge>
              <Text subtle>Choose your side.... wisely.</Text>
            </VStack>
          </FillSpace>

          <HStack>
            <ButtonGroup>
              <SplitButton selected={true}>Light</SplitButton>
              <SplitButton selected={false} onClick={notSupported}>
                Dark
              </SplitButton>
              <SplitButton selected={false} onClick={notSupported}>
                System
              </SplitButton>
            </ButtonGroup>
          </HStack>
        </SpaceBetween>

        <Divider direction="horizontal" />

        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Tint</TextLarge>
              <Text subtle>Choose the tint color of your theme.</Text>
            </VStack>
          </FillSpace>

          <HStack>
            {map(SUGGESTED, (color) => (
              <Icon
                key={color}
                className={cx(themeColor === color && styles.selected)}
                size="large"
                icon={color ? <Swatch color={color} /> : LineAlt}
                onClick={() => setThemeColor(color)}
              />
            ))}
            <Divider direction="vertical" />
            <ColorSelect
              color={themeColor}
              onChange={(c) => c && setThemeColor(c)}
            >
              <Icon
                size="large"
                icon={themeColor ? <Swatch color={themeColor} /> : LineAlt}
              />
            </ColorSelect>
          </HStack>
        </SpaceBetween>

        <Divider direction="horizontal" />
      </VStack>
    </Centered>
  );
}
