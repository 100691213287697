import { useRecoilValue } from "recoil";
import { filter, find, keys, map, reduce, set, some, uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  DisplayAs,
  EntityType,
  HasSettings,
  ID,
  isPerson,
  PropertyDef,
} from "@api";

import {
  useGetItemFromAnyStore,
  useLazyEntity,
  useUpdateEntity,
} from "@state/generic";
import { useEntityLabels, useEntitySettings } from "@state/settings";
import {
  allRelationsForTeam,
  useCreatePropertyDef,
  useDeletePropertyDef,
} from "@state/databases";
import { useActiveWorkspaceId } from "@state/workspace";

import { Maybe, maybeMap, SafeRecord, when } from "@utils/maybe";
import {
  getSetting,
  isAnyRelation,
  toConnectingRefsProp,
} from "@utils/property-refs";
import { ensureMany, indexBy, justOne, overlaps } from "@utils/array";
import { toLocation } from "@utils/scope";
import { asMutation } from "@utils/property-mutations";
import { all, mapAll } from "@utils/promise";
import { use } from "@utils/fn";
import { plural } from "@utils/string";
import { debug } from "@utils/debug";

import { PACKAGES, SystemPackages } from "./definitions";
import { toPackageName } from "./utils";
import { throwStar } from "@utils/wildcards";

export function useHasPackages(
  entityId: ID,
  packages: ID[]
): Record<string, Maybe<boolean>> {
  const settings = useEntitySettings(entityId);
  return useMemo(
    () =>
      reduce(
        packages,
        (r, p) => set(r, p, Boolean(getSetting(settings, p))),
        {}
      ),
    [settings]
  );
}

export function useAnyHasPackages(
  entityIds: ID[],
  packages: ID[]
): Record<string, Maybe<boolean>> {
  const getItem = useGetItemFromAnyStore();
  return useMemo(() => {
    const allSettings = maybeMap(
      entityIds,
      (id) => (getItem(id) as Maybe<HasSettings>)?.settings
    );
    return reduce(
      packages,
      (r, p) =>
        set(
          r,
          p,
          some(allSettings, (settings) => Boolean(getSetting(settings, p)))
        ),
      {}
    );
  }, [entityIds, packages?.length]);
}

// Update on new entity

const PACKAGE_MAPPINGS: Partial<SafeRecord<SystemPackages, EntityType>> = {
  [SystemPackages.Projects]: "project",
  [SystemPackages.Calendars]: "calendar",
  [SystemPackages.Roadmaps]: "roadmap",
  [SystemPackages.Campaigns]: "campaign",
  [SystemPackages.Content]: "content",
  [SystemPackages.Backlogs]: "backlog",
  [SystemPackages.Outcomes]: "outcome",
  [SystemPackages.Sprints]: "sprint",
  [SystemPackages.Tasks]: "task",
  [SystemPackages.Pages]: "page",
  [SystemPackages.Forms]: "form",
  [SystemPackages.Meetings]: "meeting",
  [SystemPackages.Agendas]: "agenda",
  [SystemPackages.Actions]: "action",
  [SystemPackages.Processes]: "process",
  [SystemPackages.KnowledgeBase]: "knowledgebase",
  [SystemPackages.Workflows]: "workflow",
  [SystemPackages.Pipelines]: "pipeline",
  [SystemPackages.Events]: "event",
  [SystemPackages.Requests]: "request",
  [SystemPackages.Contacts]: "contact",
  [SystemPackages.Companys]: "company",
  [SystemPackages.Deals]: "deal",
};
const ALL_PACKAGES = keys(PACKAGE_MAPPINGS);

export const toInstalledEntities = (settings: HasSettings["settings"]) =>
  maybeMap(ALL_PACKAGES, (pkg) =>
    getSetting(settings, pkg)
      ? (PACKAGE_MAPPINGS[pkg as SystemPackages] as EntityType)
      : undefined
  );

export const useInstalledEntities = (entityId: Maybe<ID>) => {
  const workspaceId = useActiveWorkspaceId();
  const entity = useLazyEntity(entityId);
  const installed = useAnyHasPackages(
    useMemo(() => {
      if (!entityId) {
        return [];
      }

      return isPerson(entity)
        ? [workspaceId, ...map(entity.teams, (i) => i.id)]
        : uniq([workspaceId, entityId]);
    }, [entity]),
    ALL_PACKAGES
  );

  return useMemo(
    (): EntityType[] =>
      maybeMap(ALL_PACKAGES, (pkg) =>
        installed[pkg]
          ? (PACKAGE_MAPPINGS[pkg as SystemPackages] as EntityType)
          : undefined
      ),
    [installed]
  );
};

export const useInstalledEntitiesForSource = (scope: string) => {
  const entityId = useMemo(() => toLocation(scope)?.split("/")?.[0], [scope]);
  return useInstalledEntities(entityId);
};

// TODO: Move to an Atom
export const useLazyPackage = (pkg: ID) => {
  return useMemo(() => find(PACKAGES, (p) => p.id === pkg), [pkg]);
};

export const usePackageInstaller = (teamId: ID, pageId?: string) => {
  const [installing, setInstalling] = useState<string>();
  const settings = useEntitySettings(teamId);
  const entity = useLazyEntity(teamId);
  const update = useUpdateEntity(teamId, pageId);
  const create = useCreatePropertyDef(entity?.source);
  const getPackage = (id: ID) => find(PACKAGES, { id });

  const install = useCallback(
    async (pkg: ID) => {
      // TODO: Move to atom store lookup
      const packagee = getPackage(pkg);

      if (!packagee) return;

      setInstalling(pkg);

      // Create all props for entities in use in this team
      await mapAll(packagee.props || [], async (prop) => {
        if ((ensureMany(prop.options?.references)?.length || 0) > 1) {
          throw new Error("Can't install multi-reference props yet.");
        }

        const referencing = justOne(throwStar(prop.options?.references) || []);
        const isSelfReference = packagee.entity === referencing;

        // If the other entity is not installed, skip
        if (
          // There is a reference set and it's not installed
          referencing &&
          !getSetting(settings, toPackageName(referencing)) &&
          // And it's not a self-references
          !isSelfReference
        ) {
          debug(
            "Skipping prop as it is not installed or does not reference another entity.",
            `${prop.entity[0]}.${prop.field}`,
            prop,
            settings,
            referencing
          );
          return;
        }

        await create(prop, prop, {
          type: packagee.entity || prop.entity[0],
          scope: teamId,
        });

        // New package represents a core entity (not self-reference)
        // Go through all fields for the related package and see if it had any references
        // pointing back to this newly installed entity and install them.
        if (packagee.entity && referencing && !isSelfReference) {
          const referenced = getPackage(toPackageName(referencing));
          await mapAll(referenced?.props || [], async (inverse) => {
            if (inverse.options?.references === packagee.entity) {
              await create(inverse, inverse, {
                type: referencing,
                scope: teamId,
              });
            }
          });
        }
      });

      // Mark package as installed on team
      update(asMutation({ field: `settings.${pkg}`, type: "boolean" }, true));

      setInstalling(undefined);
    },
    [entity, settings]
  );

  return useMemo(
    () => ({ install, loading: !!installing, installing }),
    [install, installing]
  );
};

export const usePackageUninstaller = (teamId: ID, pageId?: string) => {
  const [uninstalling, setUninstalling] = useState<string>();
  const settings = useEntitySettings(teamId);
  const entity = useLazyEntity(teamId);
  const update = useUpdateEntity(teamId, pageId);
  const deletee = useDeletePropertyDef(entity?.source);
  const props = useRecoilValue(allRelationsForTeam(teamId));
  const getPackage = (id: ID) => find(PACKAGES, { id });

  const uninstall = useCallback(
    async (pkg: ID) => {
      // TODO: Move to atom store lookup
      const packagee = getPackage(pkg);

      if (!packagee) return;

      setUninstalling(pkg);

      // Find all properties that are related to this package
      const referencesEntity = filter(
        packagee?.entity ? props : [],
        (p) =>
          // Is a hierarchy relation and references the package entity
          isAnyRelation(p) &&
          !!p.options?.hierarchy &&
          (p.entity[0] === packagee.entity ||
            p.options?.references === packagee.entity)
      );
      const installedByPackage = use(
        indexBy(packagee.props || [], (p) => p.field),
        (lookup) =>
          filter(
            props,
            (p) =>
              when(lookup[p.field]?.entity, (t) => overlaps(t, p.entity)) ??
              false
          )
      );

      // Remove all props installed by the package or referencing the package entity
      await mapAll(
        [...referencesEntity, ...installedByPackage],
        async (prop) => {
          await deletee(prop, {
            type: prop.entity[0],
            scope: teamId,
          });
        }
      );

      // Mark package as uninstalled on team
      update(asMutation({ field: `settings.${pkg}`, type: "boolean" }, false));

      setUninstalling(undefined);
    },
    [entity, deletee, props, settings]
  );

  return useMemo(
    () => ({ uninstall, loading: !!uninstalling, uninstalling: uninstalling }),
    [uninstall, uninstalling]
  );
};

export function useRelationCreateRemove(teamId: ID) {
  const create = useCreatePropertyDef();
  const deletee = useDeletePropertyDef();
  const toLabel = useEntityLabels(teamId);

  const deleteConnection = useCallback(
    async (pair: [PropertyDef, PropertyDef]) => {
      // Remove all pairs
      await mapAll(pair, (def) =>
        deletee(def, { type: def.entity[0], scope: def.scope })
      );
    },
    [deletee]
  );

  const createConnection = useCallback(
    async (
      type: EntityType,
      references: EntityType,
      hierarchy: "parent" | "child"
    ) => {
      await all([
        // Create the relation on entity we're editing
        create(
          toConnectingRefsProp(references),
          {
            label: toLabel(references, { plural: true, case: "title" }),
            displayAs: DisplayAs.Section,
            order: 100,
            options: {
              sync: `refs.${plural(type)}`,
              hierarchy,
              references,
            },
          },
          { type, scope: teamId }
        ),
        // Create the inverse relationship on the referenced entity
        create(
          toConnectingRefsProp(type),
          {
            label: toLabel(type, { plural: true, case: "title" }),
            order: 100,
            displayAs: DisplayAs.Section,
            options: {
              sync:
                hierarchy === "parent"
                  ? toConnectingRefsProp(references)?.field
                  : undefined,
              hierarchy: hierarchy === "parent" ? "child" : "parent",
              references: type,
            },
          },
          { type: references, scope: teamId }
        ),
      ]);
    },
    []
  );

  return useMemo(
    () => ({ deleteConnection, createConnection }),
    [deleteConnection, createConnection]
  );
}
