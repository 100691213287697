import { useLocation } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { filter, map } from "lodash";
import { useRecoilState } from "recoil";

import { Team } from "@api";

import { toManagePackages, useLazyAllTeams, useTeamPushTo } from "@state/teams";
import {
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useLogout,
  useUpdateEntity,
} from "@state/generic";
import { useCurrentUser } from "@state/workspace";
import { useOpenAppCommands, useOpenRecents } from "@state/app";
import { sortByCloseness, useLazyAllPersons, useMe } from "@state/persons";
import { toTemplateViewId } from "@state/views";
import { GlobalFetchOptionsAtom, showArchived } from "@state/fetch-results";

import { maybeMap } from "@utils/maybe";
import { useShortcut } from "@utils/event";
import { useGoTo } from "@utils/navigation";
import { cx } from "@utils/class-names";
import { dependencySort } from "@utils/array";
import { useShowMore, useStickyState } from "@utils/hooks";
import { containsRef } from "@utils/relation-ref";
import { asAppendMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";

import { Menu } from "@ui/menu";
import { Text } from "@ui/text";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { WorkspaceSelect } from "@ui/workspace-select";
import { AddMenuItem, EmptyMenuItem } from "@ui/menu-item";
import { MenuGroup } from "@ui/menu-group";
import {
  ArrowRight,
  Cog,
  EmojiIcon,
  Building,
  Icon,
  PersonIcon,
  PlusIcon,
  Search,
  TeamIcon,
  CounterIcon,
  ArchiveOpen,
  Archive,
  ClockHistory,
  PinSlash,
  StatusLive,
} from "@ui/icon";
import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween } from "@ui/flex";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { ViewCreateDialog } from "@ui/engine/view";
import { RelationIcon, RelationLabel } from "./relation-label";
import { TeamCreateDialog } from "@ui/engine/team";
import { SmartDefaultsAddWorkDialog } from "./add-work-dialog";
import { ExpandableMenuItem } from "@ui/menu-item/expandable";
import { CollapsibleMenuItem } from "@ui/menu-item/collapsible";
import { GlobalEntitySelect } from "@ui/select";
import { PersonStatusIcon } from "./person-status-icon";
import { Ellipsis } from "@ui/ellipsis";

import styles from "./primary-nav.module.css";

export default function PrimaryNav() {
  const goTo = useGoTo();
  const me = useCurrentUser();
  const path = useLocation();
  const fullMe = useLazyEntity<"person">(me.id);
  const pinned = useLazyEntities(fullMe?.refs?.pins);
  const allTeams = useLazyAllTeams();
  const allPeople = useLazyAllPersons();
  const [fetchOptions, setFetchOptions] = useRecoilState(
    GlobalFetchOptionsAtom
  );
  const mutate = useUpdateEntity(me.id);
  const [dialog, setDialog] = useState<"view" | "team" | "add-work">();
  const [collapsed, setCollapsed] = useStickyState<boolean>(
    false,
    "primary-nav-open"
  );
  const [showAll, setShowAll] = useStickyState<boolean>(
    false,
    "nav-show-all-teams"
  );

  const openSearch = useOpenAppCommands();
  const openRecents = useOpenRecents();

  const teams = useMemo(() => {
    const filtered = showAll
      ? allTeams
      : filter(allTeams, (t) => containsRef(t.people, me));
    return dependencySort(filtered, (t) => t.parent?.id);
  }, [allTeams, showAll]);

  const people = useShowMore(
    useMemo(() => sortByCloseness(allPeople, teams, me), [allPeople, teams]),
    collapsed ? 4 : 4
  );

  const logout = useLogout();
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );

  useShortcut({ command: true, key: "Backslash" }, toggleCollapsed, [
    toggleCollapsed,
  ]);

  return (
    <div className={cx(styles.root, collapsed && styles.collapsed)}>
      {dialog === "team" && (
        <TeamCreateDialog
          defaults={{}}
          onCancel={() => setDialog(undefined)}
          onSaved={(t) => {
            setDialog(undefined);
            goTo(toManagePackages(t.id));
          }}
        />
      )}

      {dialog === "view" && (
        <ViewCreateDialog
          defaults={{}}
          onCancel={() => setDialog(undefined)}
          onSaved={(view) => {
            goTo(view);
            setDialog(undefined);
          }}
        />
      )}

      {dialog === "add-work" && (
        <SmartDefaultsAddWorkDialog
          defaults={{}}
          onCancel={() => setDialog(undefined)}
          onSaved={(tasks) => {
            goTo(tasks);
            setDialog(undefined);
          }}
        />
      )}

      <WorkspaceSelect collapsed={collapsed} onCollapse={toggleCollapsed} />

      <Container
        fit="container"
        padding="none"
        stack="vertical"
        className={cx(styles.newSearch)}
        align="center"
        gap={10}
      >
        <Divider />

        <SpaceBetween
          gap={6}
          direction={collapsed ? "vertical" : "horizontal"}
          width="container"
        >
          <Button
            subtle
            fit="content"
            size="small"
            className={cx(styles.primaryAction, styles.searchButton)}
            onClick={openSearch}
          >
            <Icon icon={Search} />
          </Button>
          <Button
            subtle
            fit="content"
            size="small"
            className={cx(styles.primaryAction, styles.searchButton)}
            onClick={openRecents}
          >
            <Icon icon={ClockHistory} />
          </Button>
          <Button
            variant="primary"
            fit="content"
            size="small"
            className={cx(styles.primaryAction, styles.newButton)}
            onClick={() => setDialog("add-work")}
          >
            <Icon icon={PlusIcon} className={styles.icon} />
          </Button>
        </SpaceBetween>

        <CurrentMeetingButton />

        <Divider />
      </Container>

      <Container
        padding="none"
        fit="container"
        className={cx(styles.menuContainer, styles.fill)}
      >
        <Menu>
          <MenuGroup>
            <CollapsibleMenuItem
              className={styles.menuItem}
              collapsed={collapsed}
              onClick={() => goTo("/home")}
              icon={<EmojiIcon emoji="🏠" />}
              selected={path?.pathname?.includes("/home")}
            >
              Home
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              className={styles.menuItem}
              collapsed={collapsed}
              onClick={() => goTo("/inbox")}
              icon={<EmojiIcon emoji="📥" />}
              selected={path?.pathname?.includes("/inbox")}
            >
              Inbox
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              className={styles.menuItem}
              collapsed={collapsed}
              onClick={() =>
                goTo([
                  "boards",
                  toTemplateViewId("my-tasks", {
                    parent: me.id,
                    entity: "task",
                  }),
                ])
              }
              icon={<PersonIcon person={me} />}
              selected={path?.pathname?.startsWith("/boards")}
            >
              My Work
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              className={styles.menuItem}
              collapsed={collapsed}
              onClick={() => goTo(["meetings"])}
              icon={<EmojiIcon emoji="🤝" />}
              selected={path?.pathname?.startsWith("/meetings")}
            >
              My Meetings
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              className={styles.menuItem}
              onClick={() => goTo(["teams"])}
              selected={path?.pathname?.startsWith("/teams")}
              icon={Building}
              collapsed={collapsed}
            >
              All Teams
            </CollapsibleMenuItem>
          </MenuGroup>

          {(!collapsed || !!pinned?.length) && (
            <MenuGroup label="Pinned">
              {maybeMap(pinned, (v) => (
                <ContextMenu
                  key={v.id}
                  actions={
                    <ContextItem
                      icon={PinSlash}
                      onClick={() =>
                        mutate(
                          asAppendMutation(
                            { field: "refs.pins", type: "relations" },
                            [toRef(v)],
                            "remove"
                          )
                        )
                      }
                    >
                      Unpin
                    </ContextItem>
                  }
                >
                  <CollapsibleMenuItem
                    collapsed={collapsed}
                    className={styles.menuItem}
                    onClick={() => goTo(v)}
                    icon={<RelationIcon relation={v} />}
                  >
                    <RelationLabel icon={false} relation={v} />
                  </CollapsibleMenuItem>
                </ContextMenu>
              ))}

              {!collapsed && (
                <GlobalEntitySelect
                  value={undefined}
                  onChange={(r) =>
                    r &&
                    mutate(
                      asAppendMutation(
                        { field: "refs.pins", type: "relations" },
                        [r]
                      )
                    )
                  }
                  type="team"
                  allowed="*"
                  showOtherTeams={true}
                >
                  <AddMenuItem subtle title={"Add pin"} />
                </GlobalEntitySelect>
              )}
            </MenuGroup>
          )}

          <MenuGroup
            label={showAll || collapsed ? "Teams" : "My Teams"}
            onLabelClicked={() => {
              setShowAll(!showAll);
              setCollapsed(false);
            }}
          >
            {map(teams, (t, i) => (
              <TeamMenuItem
                key={t.id}
                team={t}
                highlighted={path?.pathname?.includes(t.id)}
                collapsed={collapsed}
              />
            ))}
            {!teams.length && <EmptyMenuItem text="No teams." />}
          </MenuGroup>
        </Menu>
      </Container>

      <Container className={cx(styles.bottom)} padding="none">
        <Menu>
          <MenuGroup label="People" onLabelClicked={() => setCollapsed(false)}>
            {map(people.visible, (p) => (
              <CollapsibleMenuItem
                key={p.id}
                className={cx(styles.menuItem, styles.onHover)}
                onClick={() => goTo(p)}
                icon={<PersonStatusIcon person={p} />}
                iconRight={
                  <Icon className={styles.onHoverTarget} icon={ArrowRight} />
                }
                collapsed={collapsed}
              >
                <Text>{p.name}</Text>
              </CollapsibleMenuItem>
            ))}
            {people.hasMore && (
              <CollapsibleMenuItem
                collapsed={collapsed}
                icon={<CounterIcon color="gray_5" count={people.moreCount} />}
                onClick={() => {
                  people.showMore();
                  setCollapsed(false);
                }}
              >
                <Text subtle>Show more</Text>
              </CollapsibleMenuItem>
            )}
          </MenuGroup>

          <MenuGroup label="">
            <Divider className={styles.divider} />

            <CollapsibleMenuItem
              className={cx(
                styles.menuItem,
                styles.archived,
                fetchOptions?.archived && styles.open
              )}
              onClick={() =>
                setFetchOptions(showArchived(!fetchOptions?.archived))
              }
              icon={fetchOptions?.archived ? ArchiveOpen : Archive}
              collapsed={collapsed}
            >
              {fetchOptions?.archived ? "Close archives" : "Open archives"}
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              className={styles.menuItem}
              onClick={() => goTo("/settings/themes")}
              icon={Cog}
              collapsed={collapsed}
            >
              Settings
            </CollapsibleMenuItem>

            <ActionMenu
              actions={
                <>
                  <ActionItem
                    className={styles.menuItem}
                    onClick={() => logout()}
                    icon={<EmojiIcon emoji="👋" />}
                  >
                    Logout
                  </ActionItem>
                </>
              }
            >
              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={me && <PersonIcon person={me} />}
                collapsed={collapsed}
              >
                {me?.name}
              </CollapsibleMenuItem>
            </ActionMenu>
          </MenuGroup>
        </Menu>
      </Container>
    </div>
  );
}

interface TeamMenuProps {
  team: Team;
  highlighted?: boolean;
  collapsed?: boolean;
}

export const TeamMenuItem = ({
  team,
  collapsed,
  highlighted,
}: TeamMenuProps) => {
  const goTo = useGoTo();
  const pushTo = useTeamPushTo(team.id);

  if (!team) {
    return <></>;
  }

  if (collapsed) {
    return (
      <CollapsibleMenuItem
        selected={highlighted}
        className={cx(styles.menuItem)}
        onClick={() => goTo(team)}
        collapsed={true}
        icon={<TeamIcon team={team} />}
      >
        <Text>{team.name}</Text>
      </CollapsibleMenuItem>
    );
  }

  return (
    <>
      <ExpandableMenuItem
        saveKey={team.id}
        selected={highlighted}
        className={cx(
          styles.menuItem,
          styles.teamItem,
          styles.onHover,
          styles.team
        )}
        onClick={() => goTo(team)}
        size="medium"
        exapandable={!!team?.refs?.pins?.length}
        highlight="shadow"
        icon={
          <Icon
            className={styles.teamIcon}
            icon={<TeamIcon team={team} />}
            size="medium"
          />
        }
        iconRight={
          collapsed ? undefined : (
            <Icon className={styles.onHoverTarget} icon={ArrowRight} />
          )
        }
        text={collapsed ? undefined : <Text>{team.name}</Text>}
      >
        <>
          {map(team?.refs?.pins, (r) => (
            <CollapsibleMenuItem
              key={r.id}
              collapsed={collapsed ?? false}
              indent={1}
              onClick={() => pushTo(r)}
              icon={<RelationIcon relation={r} />}
            >
              <RelationLabel icon={false} relation={r} />
            </CollapsibleMenuItem>
          ))}
        </>
      </ExpandableMenuItem>
    </>
  );
};

const CurrentMeetingButton = () => {
  const goTo = useGoTo();
  const me = useMe();

  const [current] = useLazyQuery(
    "meeting",
    useMemo(
      () => ({
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "in-progress" } },
          },
          {
            field: "refs.people",
            type: "relations",
            op: "equals",
            value: { relations: [toRef(me)] },
          },
        ],
      }),
      [me?.id]
    ),
    { limit: 1 }
  );

  return useMemo(
    () =>
      !!current && (
        <Button
          subtle
          variant="danger"
          fit="content"
          size="small"
          className={cx(styles.meetingButton)}
          onClick={() => goTo(current)}
        >
          <HStack fit="container" gap={4} className={styles.text}>
            <Icon icon={StatusLive} />
            <Ellipsis>{current.name}</Ellipsis>
          </HStack>
        </Button>
      ),
    [current?.id, current?.name]
  );
};
