import { useRecoilValue } from "recoil";
import { titleCase } from "title-case";
import { filter, groupBy, map } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { EntityType, ID, updatePropertyDef } from "@api";

import { useQueueUpdates, useUpdateEntity } from "@state/generic";
import {
  PACKAGES,
  Package,
  usePackageInstaller,
  usePackageUninstaller,
  useRelationCreateRemove,
} from "@state/packages";
import { useEntityLabels, useEntitySettings } from "@state/settings";
import { useLazyWorkspace } from "@state/workspace";
import {
  allRelationsForTeam,
  toHierarchyPairs,
  useFetchAllEntityProperties,
} from "@state/databases";

import { cx } from "@utils/class-names";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { getSetting } from "@utils/property-refs";
import { maybeMap, when } from "@utils/maybe";
import { maybeValues } from "@utils/object";
import { plural } from "@utils/string";
import { fuzzyMatch } from "@utils/search";
import { Fn, use } from "@utils/fn";
import { justOne } from "@utils/array";
import { withoutStar } from "@utils/wildcards";
import { all } from "@utils/promise";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Heading, Text, TextLarge, TextSmall } from "@ui/text";
import {
  Box,
  Cog,
  MinusIcon,
  PlusIcon,
  RelationIcon,
  TimesCircle,
} from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { Main, PageLayout } from "@ui/page-layout";
import { TeamSchemaFlow } from "@ui/team-schema-flow";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { SearchInput } from "@ui/search-input";
import { EmptyState } from "@ui/empty-state";
import { ListItem } from "@ui/list-item";
import { Ellipsis } from "@ui/ellipsis";

import styles from "./settings-packages.module.css";

export function SettingsTeamPackages({ teamId }: { teamId: ID }) {
  const pageId = usePageId();
  const [editing, setEditing] = useState<EntityType>();
  const [search, setSearch] = useState<string>();
  const settings = useEntitySettings(teamId);
  const { install, installing } = usePackageInstaller(teamId, pageId);
  const { uninstall, uninstalling } = usePackageUninstaller(teamId, pageId);
  const toLabel = useEntityLabels(teamId);

  useFetchAllEntityProperties(teamId);

  const packages = useMemo(
    () =>
      maybeMap(PACKAGES, (p) => ({
        ...p,
        installed: Boolean(getSetting<boolean>(settings, p.id)),
      })),
    [settings]
  );
  const byInstalled = useMemo(
    () => groupBy(packages, (p) => p.installed),
    [packages]
  );
  const available = useMemo(
    () =>
      search
        ? filter(byInstalled.false, (p) =>
            fuzzyMatch(search, p.name + p.description)
          )
        : byInstalled.false,
    [byInstalled, search]
  );

  const handleShowSettings = useCallback((id: ID) => {
    if (id?.startsWith("pkg_")) {
      // Packages are in the format `pkg_<id>`
      const type = id.split("_")[1] as EntityType;
      // TODO: Validate type
      setEditing(type);
    } else {
      setEditing(id as EntityType);
    }
  }, []);

  const handleInstall = useCallback((id: ID) => install(id), [install]);

  const handleUninstall = useCallback((id: ID) => uninstall(id), [uninstall]);

  return (
    <PageLayout>
      {editing && (
        <EditPackageSettings
          teamId={teamId}
          type={editing}
          onClose={() => setEditing(undefined)}
        />
      )}
      <Main>
        <Container size="double" gap={20} stack="vertical">
          <SpaceBetween>
            <VStack gap={0}>
              <Heading bold>Packages</Heading>
              <Text subtle>
                Install packages to add new functionality to your team.
              </Text>
            </VStack>
            <TeamSchemaFlow
              teamId={teamId}
              className={styles.flow}
              interactable={false}
            />
          </SpaceBetween>

          <CollapsibleSection title="Installed">
            <Container
              padding="none"
              stack="vertical"
              // wrap
              fit="container"
              gap={0}
              inset="left"
            >
              {map(byInstalled.true, (p) => (
                <PackageListItem
                  key={p.id}
                  package={p}
                  installed={true}
                  alias={when(p.entity, toLabel)}
                  onInstalled={handleUninstall}
                  onShowSettings={handleShowSettings}
                  loading={uninstalling === p.id}
                />
              ))}
            </Container>
          </CollapsibleSection>

          <CollapsibleSection
            title="Browse"
            actions={
              <HStack>
                <SearchInput search={search} setSearch={setSearch} />
              </HStack>
            }
          >
            <HStack wrap fit="container" gap={0}>
              {map(available, (p) => (
                <PackageListItem
                  key={p.id}
                  package={p}
                  installed={false}
                  onInstalled={handleInstall}
                  loading={installing === p.id}
                />
              ))}
              {!available.length && <EmptyState text={"No packages..."} />}
            </HStack>
          </CollapsibleSection>
        </Container>
      </Main>
    </PageLayout>
  );
}

export function SettingsWorkspacePackages() {
  const pageId = usePageId();
  const workspace = useLazyWorkspace();
  const mutate = useQueueUpdates(pageId);
  const settings = workspace?.settings;

  const packages = useMemo(
    () =>
      settings
        ? map(PACKAGES, (p) => ({
            ...p,
            installed: Boolean(getSetting<boolean>(settings, p.id)),
          }))
        : [],
    [settings]
  );
  const byInstalled = useMemo(
    () => groupBy(packages, (p) => p.installed),
    [packages]
  );

  const onInstalled = useCallback((id: string, installed: boolean) => {
    workspace &&
      mutate(
        asUpdate(
          workspace,
          asMutation({ field: `settings.${id}`, type: "boolean" }, installed)
        )
      );
  }, []);

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Workspace Properties</Heading>
        <Text subtle>
          These properties are shared across your whole workspace. Changes here
          will effect all teams.
        </Text>
      </VStack>

      <Divider direction="horizontal" />

      <CollapsibleSection title="Installed">
        <Container
          padding="none"
          stack="horizontal"
          wrap
          fit="container"
          gap={0}
          inset="left"
          align="flex-start"
          justify="flex-start"
        >
          {map(byInstalled.true, (p) => (
            <PackageListItem
              key={p.id}
              package={p}
              installed={true}
              onInstalled={onInstalled}
            />
          ))}
        </Container>
      </CollapsibleSection>

      <CollapsibleSection title="Browse">
        <HStack
          wrap
          fit="container"
          gap={0}
          justify="flex-start"
          align="flex-start"
        >
          {map(byInstalled.false, (p) => (
            <PackageListItem
              key={p.id}
              package={p}
              installed={false}
              onInstalled={onInstalled}
            />
          ))}
        </HStack>
      </CollapsibleSection>
    </Centered>
  );
}

interface PackageProps {
  package: Package;
  alias?: string;
  installed: boolean;
  loading?: boolean;
  onInstalled: (id: ID, installed: boolean) => void;
  onShowSettings?: (id: ID) => void;
  className?: string;
}

function PackageListItem({
  package: { id, name, description, icon, available = true },
  alias,
  installed,
  onInstalled,
  onShowSettings,
  className,
  loading,
}: PackageProps) {
  return (
    <ListItem className={cx(className)}>
      <SpaceBetween fit="container">
        <FillSpace direction="horizontal">
          <VStack gap={0} fit="container">
            <Label subtle={!available} bold icon={icon || Box}>
              {name}
              {alias && !name?.includes(alias) ? ` (${alias})` : ""}
            </Label>
            {!installed && (
              <Ellipsis>
                <TextSmall subtle>{description}</TextSmall>
              </Ellipsis>
            )}
          </VStack>
        </FillSpace>
        {installed && (
          <HStack>
            <Button
              size="small"
              subtle
              icon={Cog}
              onClick={() => onShowSettings?.(id)}
            />
            <Divider direction="vertical" />
            <Button
              variant="secondary"
              size="tiny"
              onClick={() => onInstalled(id, false)}
              loading={loading}
            >
              Uninstall
            </Button>
          </HStack>
        )}
        {!installed && (
          <Button
            variant="primary"
            size="small"
            disabled={!available}
            onClick={() => onInstalled(id, true)}
            loading={loading}
          >
            Install
          </Button>
        )}
      </SpaceBetween>
    </ListItem>
  );
}

interface EditPackageProps {
  teamId: ID;
  type: EntityType;
  onClose: Fn<void, void>;
}

const EditPackageSettings = ({ teamId, type, onClose }: EditPackageProps) => {
  useFetchAllEntityProperties(teamId);

  const modalRef = useRef<HTMLDivElement>(null);
  const settings = useEntitySettings(teamId);
  const allProps = useRecoilValue(allRelationsForTeam(teamId));
  const mutate = useUpdateEntity(teamId);
  const toEntityLabel = useEntityLabels(teamId, { plural: true });
  const { createConnection, deleteConnection } =
    useRelationCreateRemove(teamId);
  const props = useRecoilValue(allRelationsForTeam(teamId));
  const pairsLookup = useMemo(
    () => toHierarchyPairs(props, type),
    [props, type]
  );
  const allPairs = useMemo(() => maybeValues(pairsLookup), [pairsLookup]);
  const pairs = useMemo(
    () => groupBy(allPairs, ([def]) => def.options?.hierarchy),
    [allPairs]
  );
  const exclude = useMemo(
    () => ({
      parent: maybeMap(pairs.parent || [], ([def]) =>
        justOne(withoutStar(def.options?.references))
      ),
      child: maybeMap(pairs.child || [], ([def]) =>
        justOne(withoutStar(def.options?.references))
      ),
    }),
    [pairs]
  );

  const alias = useMemo(
    () => getSetting<string>(settings, `${type}_alias`) || "",
    [settings]
  );

  const handleAliasEntity = useCallback(
    async (alias: string) => {
      mutate(
        asMutation({ field: `settings.${type}_alias`, type: "text" }, alias)
      );

      // Rename all properties that reference this entity to use the new alias
      await all(
        maybeMap(allProps, async (p) => {
          if (p?.options?.references === type) {
            await updatePropertyDef(
              {
                scope: p.scope,
                type: justOne(p.entity) as EntityType,
              },
              p,
              {
                label:
                  p.type === "relation"
                    ? titleCase(alias)
                    : plural(titleCase(alias)),
              }
            );
          }
        })
      );
    },
    [settings, allProps]
  );

  return (
    <Modal
      ref={modalRef}
      open={true}
      onOpenChanged={() => onClose()}
      className={styles.settings}
    >
      <VStack gap={16}>
        <SpaceBetween>
          <TextLarge bold className={styles.primary}>
            {titleCase(plural(type))}
          </TextLarge>

          <HStack>
            <Label subtle icon={Box}>
              Package Settings
            </Label>
            <Button
              subtle
              size="small"
              icon={TimesCircle}
              onClick={() => onClose()}
            />
          </HStack>
        </SpaceBetween>

        <Divider direction="horizontal" />

        <Field label="Known as">
          <TextInput
            value={alias}
            placeholder={titleCase(type)}
            updateOn="blur"
            autoFocus={false}
            onChange={handleAliasEntity}
          />
        </Field>

        <Field label="Parents">
          {!pairs.parent?.length && (
            <MenuItem className={styles.control} disabled>
              <Text subtle>No parents (top level in team)</Text>
            </MenuItem>
          )}
          {maybeMap(pairs.parent || [], (pair) =>
            use(justOne(withoutStar(pair[0].options?.references)), (type) => (
              <MenuItem
                key={type}
                className={styles.control}
                icon={RelationIcon}
              >
                <SpaceBetween>
                  <Text>{toEntityLabel(type)}</Text>
                  <Button
                    subtle
                    icon={MinusIcon}
                    size="tiny"
                    onClick={() => deleteConnection(pair)}
                  />
                </SpaceBetween>
              </MenuItem>
            ))
          )}

          <EntityTypeSelect
            value={undefined}
            scope={teamId}
            exclude={exclude.parent}
            onChange={(t) => createConnection(type, t, "parent")}
          >
            <MenuItem icon={PlusIcon}>
              <Text subtle>Add parent</Text>
            </MenuItem>
          </EntityTypeSelect>
        </Field>

        <Field label="Nested">
          {maybeMap(pairs.child || [], (pair) =>
            use(justOne(withoutStar(pair[0].options?.references)), (type) => (
              <MenuItem
                key={type}
                className={styles.control}
                icon={RelationIcon}
                onClick={() => {}}
              >
                <SpaceBetween>
                  <Text>{toEntityLabel(type)}</Text>
                  <Button
                    subtle
                    icon={MinusIcon}
                    size="tiny"
                    onClick={() => deleteConnection(pair)}
                  />
                </SpaceBetween>
              </MenuItem>
            ))
          )}

          <EntityTypeSelect
            value={undefined}
            scope={teamId}
            exclude={exclude.child}
            onChange={(t) => createConnection(type, t, "child")}
          >
            <MenuItem icon={PlusIcon}>
              <Text subtle>Add child</Text>
            </MenuItem>
          </EntityTypeSelect>
        </Field>
      </VStack>
    </Modal>
  );
};
