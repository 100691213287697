import { useMemo } from "react";

import { useGoTo, usePushTo } from "@utils/navigation";
import { cx } from "@utils/class-names";

import { MenuItem, MenuItemProps } from "./default";

import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import { ArrowRight } from "@ui/icon";

const trimTrailingSlash = (str: string) =>
  str.endsWith("/") ? str.slice(0, -1) : str;

export function RouteMenuItem({
  text,
  route,
  onClick,
  children,
  iconRight,
  mode = "goto",
  ...props
}: MenuItemProps & { route: string; mode?: "push" | "goto" }) {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const open = mode === "push" ? pushTo : goTo;

  const location = useLocation();
  const active = useMemo(
    () =>
      trimTrailingSlash(location.pathname).endsWith(trimTrailingSlash(route)),
    [route, location]
  );

  return (
    <MenuItem
      className={cx(active && styles.routeActive)}
      onClick={(e) => (route && !onClick ? open(route) : onClick?.(e))}
      highlight="shadow"
      iconRight={iconRight || (active ? ArrowRight : undefined)}
      {...props}
    >
      {text || children}
    </MenuItem>
  );
}
